<!-- index -->
<template>
  <div>
    <div class="index" v-if="!$root.isApp">
      <div class="main">
        <h3>枫华嘉美</h3>
        <h4>二十年沉淀，品质如一</h4>

        <p>您身边的跨境电商物流优质服务商，依托华运国际物流二十年平台经验，</p>
        <p>为您提供专业的欧美FBA头程空海派运输、海外仓等服务。</p>
        <p>优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派</p>
        <img
          src="../../assets/img/pc/pc_ad_special_offer.png"
          alt="枫华嘉美"
          style="width: 100%; margin-top: 30px"
        />
      </div>
      <div class="search-class">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="拼柜" name="first">
            <ul class="search-ul">
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="PortVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    起运港不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">起运港</span>
                    <el-select
                      v-model="startPort"
                      filterable
                      remote
                      placeholder="请输入"
                      :remote-method="initPort"
                      @focus="onFocus"
                      @change="selectPort"
                    >
                      <el-option
                        v-for="(item , index)  in hotPortList"
                        :key="index"
                        :label="item.cname"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-popover>
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="CodesVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    仓库代码不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">仓库代码</span>
                    <el-select
                      v-model="startCode"
                      filterable
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                      <el-option
                        v-for="(item , index) in BaseCodeList"
                        :key="index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-popover>
              </li>
              <li class="search-li">
                <!-- <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="TypeVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    类型不能为空！
                  </div> -->
                <div slot="reference" class="search_main">
                  <span class="search-titles">类型</span>
                  <el-select
                    v-model="startType"
                    placeholder="请选择"
                    @change="typeChange"
                  >
                    <el-option
                      v-for="(item ,index) in typeList"
                      :key="index"
                      :label="item.value"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <!-- </el-popover> -->
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="WeightVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    重量不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">重量</span>
                    <el-input
                      style="width: 50%"
                      v-model="startWeight"
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                    </el-input>
                  </div>
                </el-popover>
                <span class="search-unit">KG</span>
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="VolumeVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    体积不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">体积</span>
                    <el-input
                      style="width: 50%"
                      v-model="startVolume"
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                    </el-input>
                  </div>
                </el-popover>
                <span class="search-unit">CBM</span>
              </li>
            </ul>
            <div class="comfire-bottom">
              <div @click="GoSearch(isChoose)" class="search-btn">查询</div>
              <div @click="orderFn(activeName)" class="search-btn">一键下单</div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="整柜" name="second">
            <ul class="search-ul">
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="PortVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    起运港不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">起运港</span>
                    <el-select
                      v-model="startPort"
                      filterable
                      remote
                      placeholder="请输入"
                      :remote-method="initPort"
                      @focus="onFocus"
                      @change="selectPort"
                    >
                      <el-option
                        v-for="(item ,index) in hotPortList"
                        :key="index"
                        :label="item.cname"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-popover>
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="CodeVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    仓库代码不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">仓库代码</span>
                    <el-select
                      v-model="startCode"
                      filterable
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                      <el-option
                        v-for="(item,index) in BaseCodeList"
                        :key="index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-popover>
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="CabinetVisible"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    柜型不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">柜型</span>
                    <el-select
                      v-model="startCabinet"
                      filterable
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                      <el-option
                        v-for="(item,index) in CabinetType"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-popover>
              </li>
              <li class="search-li">
                <el-popover
                  trigger="manual"
                  placement="bottom"
                  v-model="CabinetNumViseble"
                >
                  <div>
                    <i class="search-tip-icon el-icon-warning"></i>
                    柜量不能为空！
                  </div>
                  <div slot="reference" class="search_main">
                    <span class="search-titles">柜量</span>
                    <el-input
                      style="width: 50%"
                      v-model="startCabinetNum"
                      placeholder="请输入"
                      @focus="onFocus"
                    >
                    </el-input>
                  </div>
                </el-popover>
              </li>
            </ul>
            <div class="comfire-bottom">
              <div @click="GoSearch(isChoose)" class="search-btn">查询</div>
              <div @click="orderFn(activeName)" class="search-btn">一键下单</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>


        <el-dialog
            class="file-dialog"
            title="一键下单"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible">
      <div class="order-file">
        <el-form :model="orderForm"  ref="ruleForm" :rules="rules">
          <div class="order-file-main">
            <div class="order-file-main-top">
              <el-form-item  prop="booking_type"  label="订单类型" >
                  <el-select v-model="orderForm.booking_type" @change="orderFn(orderForm.booking_type)" placeholder="请选择">
                    <el-option
                        v-for="(value,index) in booking_list"
                        :key="index"
                        :label="value"
                        :value="index">
                    </el-option>
                  </el-select>
              </el-form-item>
            </div>


            <div class="order-file-main-top">
              <el-form-item  prop=""  label="是否购买保险" >
                <el-radio-group v-model="orderForm.is_safe" @change="safeFn">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <div v-if="orderForm.is_safe == 1" >
                <el-form-item  prop="goods_price"  label="货物价值" class="form-insure">
                  <el-input size="mini" placeholder="" :disabled="true" v-model="orderForm.goods_price"></el-input>
                  <el-tooltip class="item" effect="light" placement="bottom-start">
                    <i class="el-icon-info"></i>
                    <div slot="content">保险费率按货值千分之二自动计算,最低保费为60</div>
                  </el-tooltip>
                </el-form-item>

                <el-form-item  prop="safe_price"  label="保险费" class="form-insure">
                  <div class="insure-input"><el-input size="mini" placeholder="" :disabled="true" v-model="orderForm.safe_price"></el-input></div>
                </el-form-item>
              </div>


            </div>
            <div class="order-file-main-center" v-if="orderForm.booking_type">
              <div>文件上传</div>
              <div>
                <el-upload
                    class="upload-demo"
                    :action="handleUploadsApi"
                    :show-file-list="false"
                    :on-success="successFile"
                    :data="uploadData"
                  >
                  <el-button size="small" type="primary" :disabled="fileList.length > 0">上传</el-button>
                </el-upload>
              </div>
              <div><a  @click="downFile">下载文件模板</a></div>
            </div>
            <div class="file-list" v-if="fileList && orderForm.booking_type">
              <div v-for="item in fileList">
                <img src="../../assets/img/pc/icon_file.png" alt=""> <span>{{item.name}} &nbsp;&nbsp;&nbsp;</span> <span class="el-icon-delete" @click="deleteFile"></span>
              </div>
            </div>
            <div class="order-file-main-bottom" @click="comfire_order('ruleForm')"> 一键下单 </div>
          </div>
        </el-form>
      </div>
        </el-dialog>

      <el-dialog
          class="comfire-dialog"
          v-loading="loading"
          :close-on-click-modal="false"
          title="确认下单"
          :visible.sync="dialogVisibleOrder">
          <div class="comfire-order">
            <div class="comfire-order-detail">
              <el-row :gutter="20">
                <el-col :span="8"><div class="comfire-order-detail-title">起运港: <span> {{ sf_data.head.pol_name }}</span> </div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">亚马逊仓库: <span> {{sf_data.head.fba_code }}</span></div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">邮编: <span> {{ sf_data.head.fba_zip_code }}</span></div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">重量: <span> {{ sf_data.head.weight }}</span> </div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">体积: <span> {{ sf_data.head.volume }}</span></div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">时效: <span> </span></div></el-col>
                <el-col :span="8"><div class="comfire-order-detail-title">仓库地址: <span>{{ sf_data.head.fba_address }}</span></div></el-col>
              </el-row>
            </div>
            <div v-if="!userinfo">
              <div class="user-title">
                <div>请提供联系信息完成订舱</div>
              </div>

              <div class="comfire-order-login">
                <div>
                  <el-radio-group v-model="orderForm.pas">
                    <el-radio :label="1">手机快速订舱</el-radio>
                    <el-radio :label="2">会员登录</el-radio>
                  </el-radio-group>
                </div>

                <el-form :model="orderForm"  ref="ruleFormOrder" :rules="rulesUser">

                <div v-if="orderForm.pas == 1">
                  <div class="comfire-order-login-phone">
                    <div>手机号</div>
                    <el-form-item  prop="mobile" >
                    <div><el-input v-model="orderForm.mobile" placeholder=""></el-input></div>
                    </el-form-item>
                  </div>
                  <div class="geetest">
                    <Geetest @validResultFn="validCode"></Geetest>
                  </div>
                  <div class="comfire-order-login-message">
                    <div>短信验证码</div>
                    <div>
                      <el-form-item  prop="veriycode" >
                      <div><el-input v-model="orderForm.veriycode" placeholder=""></el-input></div>
                      </el-form-item>
                      <div @click="phone_fn('ruleFormOrder')" class="phone_code">{{ sendCodeText }}</div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="comfire-order-login-phone">
                    <div>用户名</div>
                    <el-form-item  prop="username" >
                    <div><el-input v-model="orderForm.username" placeholder="用户名/手机号/邮箱"></el-input></div>
                    </el-form-item>
                  </div>
                  <div class="comfire-order-login-message">
                    <div>密码</div>
                    <el-form-item  prop="password" >
                    <div>
                      <el-input show-password v-model="orderForm.password" placeholder="请输入密码"></el-input>
                    </div>
                    </el-form-item>
                  </div>
                </div>

                </el-form>
              </div>

            </div>
            <div class="comfire-order-button">
                <div>
                  <div @click="backFn">返回</div>
                  <div @click="sumbit_order('ruleFormOrder')">确认提交</div>
                </div>
            </div>
          </div>
      </el-dialog>

      <el-dialog
          class="order-success"
          title=""
          :visible.sync="dialogVisibleOrderSuccess">
        <div class="order-success-main">
          <div class="order-success-main-img">
            <img src="../../assets/img/pc/order_sucess.png" alt="">
          </div>
          <div class="order-success-main-title">
            订舱成功
          </div>
          <div class="order-success-main-desc">
            您的账号尚未认证，点击下方按钮成为认证会员， 可享受到搜航网专属客服，订舱更快捷迅速。
          </div>
          <div class="order-success-main-buttom" @click="userOpen" >
            认证会员
          </div>
          <div class="order-success-main-back" @click="backIndex">
              回到首页
          </div>
        </div>
      </el-dialog>

      <div class="mengceng"></div>
      <!-- <div class="beijing">

      </div> -->
      <div class="videocontainer">
        <video
          class="fullscreenvideo"
          src="../../assets/video/backgroundVideo.mp4"
          loop="loop"
          autoplay="autoplay"
          muted
          height="680"
          playsinline
        ></video>
      </div>
      <!-- <div class="ceshi">
        <video
          preload="auto"
          loop=""
          muted=""
          autoplay=""
          playsinline=""
          class="_1k6dqj1"
        >
          <source
            src="../../assets/video/backgroundVideo.mp4"
            type="video/mp4"
          />
        </video>
      </div> -->
    </div>
    <div class="footer" v-if="!$root.isApp">
      <img src="../../assets/img/index/logo_huayun.png" alt="枫华嘉美" />
      <img src="../../assets/img/index/logo_yike.png" alt="枫华嘉美" />
      <img src="../../assets/img/index/logo_souhang.png" alt="枫华嘉美" />
    </div>
    <div v-if="$root.isApp">
      <div class="indexs">
        <img
          class="indexs_img"
          src="../../assets/img/m/m_ad_special_offer.png"
          alt="枫华嘉美"
        />
        <div class="m_index">
          <div class="index_top">
            <div class="m_logo"></div>
            <div class="m_wechats" @click="toown">
              <img src="../../assets/img/m/icon_online_counsel_pc.png" alt="" />
              <span>在线咨询</span>
            </div>
          </div>

          <p class="m_theme">二十年沉淀 品质如一</p>
          <div class="m_search">
            <div class="search_theme">
              <div class="search_tab">
                <span
                  @click="mtoClick(1)"
                  :class="isChoose != 1 ? 'unchoose_tabs' : 'choose_tab'"
                  >拼柜</span
                >
                <span
                  @click="mtoClick(0)"
                  :class="isChoose != 0 ? 'unchoose_tab' : 'choose_tab'"
                  >整柜</span
                >
              </div>
              <ul class="search_item">
                <li class="item_li">
                  <div class="item_div" slot="reference">
                    <span>起运港</span>
                    <el-select
                      class="inptwidth"
                      :disabled="isportabled"
                      placeholder="请选择起运港"
                      v-model="startPort"
                      @focus="polFocus"
                    ></el-select>
                  </div>
                  <i class="el-icon-arrow-right el_arrow" @click="polFocus"></i>
                </li>
                <li class="item_li">
                  <div class="item_div" slot="reference">
                    <span>仓库代码</span>
                    <el-select
                      class="inptwidth"
                      :disabled="iscodeabled"
                      placeholder="请选择仓库代码"
                      v-model="startCode"
                      @focus="codeFocus"
                    ></el-select>
                  </div>
                  <i
                    class="el-icon-arrow-right el_arrow"
                    @click="codeFocus"
                  ></i>
                </li>
                <li class="item_li" v-if="isChoose == 1">
                  <div class="item_div" slot="reference">
                    <span>类型</span>
                    <el-select
                      v-model="startType"
                      class="inptwidth"
                      placeholder="请选择类型"
                      :disabled="istypeabled"
                      @focus="typeFocus"
                    ></el-select>
                  </div>
                  <i
                    class="el-icon-arrow-right el_arrow"
                    @click="typeFocus"
                  ></i>
                </li>
                <li class="item_li" v-if="isChoose == 1">
                  <div class="item_div" slot="reference">
                    <span>重量</span>
                    <el-input
                      class="inptwidth"
                      v-model="startWeight"
                      placeholder="请输入"
                      @focus="onFocus"
                      size="small"
                    >
                    </el-input>
                  </div>
                </li>
                <li class="item_li" v-if="isChoose == 1">
                  <div class="item_div" slot="reference">
                    <span>体积</span>
                    <el-input
                      class="inptwidth"
                      v-model="startVolume"
                      placeholder="请输入"
                      @focus="onFocus"
                      size="small"
                    >
                    </el-input>
                  </div>
                </li>
                <li class="item_li" v-if="isChoose == 0">
                  <div class="item_div" slot="reference">
                    <span>柜型</span>
                    <el-select
                      v-model="startCabinets"
                      :disabled="isCabinetabled"
                      @focus="CabinetFocus"
                      placeholder="请选择类型"
                    ></el-select>
                    <i
                      class="el-icon-arrow-right el_arrow"
                      @click="CabinetFocus"
                    ></i>
                  </div>
                </li>
                <li class="item_li" v-if="isChoose == 0">
                  <div class="item_div" slot="reference">
                    <span>柜量</span>
                    <el-input
                      class="inptwidth"
                      v-model="startCabinetNum"
                      placeholder="请输入"
                      @focus="onFocus"
                      size="small"
                    >
                    </el-input>
                  </div>
                </li>
              </ul>
              <div class="m_searchs_btn">
                <div @click="GoSearchs(isChoose)" class="m_search_btn">
                  查询
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="tonext">
            <i class="el-icon-arrow-down el_down" @click="goNext"></i>
          </div> -->
        </div>
      </div>
      <div class="m_introduce" ref="refName">
        <h2>公司简介</h2>
        <p>
          枫华嘉美是从事跨境电商物流的优质服务商，依托华运
          国际物流二十年经验和平台优势，打造出精品北美FBA 头程物流线路。
        </p>
        <br />
        <p>
          我司在美国洛杉矶和纽约设有分公司，自营20万尺海外
          仓，拥有搜航IT团队的支持，全球拥有丰富的海外代理
          资源，能够为您提供安全、专业的物流运输服务。
        </p>
        <div class="m_hope_img">
          <img src="../../assets/img/mindex/picture_fbahoppe.png" alt="枫华嘉美" />
        </div>
      </div>
      <div class="m_channel">
        <h2>优势渠道</h2>
        <ul class="channel_ul">
          <li v-for="(item, index) in channelList" :key="index">
            <img :src="item.img" alt="枫华嘉美" />
            <p>{{ item.label }}</p>
          </li>
        </ul>
      </div>
      <div class="m_recruit">
        <h2>联系我们</h2>
        <div class="recruit_r">
          <span> 电话：(86) 0755-22960000 </span>
          <span> 地址：深圳市南山区粤海街道软件产业基地4栋308</span>
        </div>
        <div class="recruit_img">
          <img src="../../assets/img/mindex/picture_contact_us.png" alt="枫华嘉美" />
        </div>
      </div>
      <div class="m_about">
        <h2>人才招聘</h2>
        <div class="to_btn" @click="goUrl()">加入我们</div>
        <div class="m_title_m">枫华嘉美跨境电商物流(深圳)有限公司</div>
      </div>
    </div>
    <search-box
      ref="searchbox"
      @set="setItem"
      @closeSearchBox="closeSearchBox"
    ></search-box>
    <div v-if="!$root.isApp" class="contactUs" @click="tous">
      <img src="../../assets/img/pc/icon_online_counsel_pc.png" alt="" />
      <span> 在线咨询 </span>
    </div>
  </div>
</template>

<script>
// 导入的其他文件 例如：import moduleName from 'modulePath';
import { getHotPorts, getPort, getFbaWh ,saveOrderApi, mobileApi,logoutApi } from "@/service/index";
import SearchBox from "../../components/searchbox/SearchBox";
import Geetest from "../../components/geetest/Geetest";
import baseApi from '@/service/base'
import axios from "@/utils/http"
export default {
  name: "Index",
  //import所引入的组件注册
  components: {
    SearchBox,
    Geetest
  },

  data() {
    var validateGood = (rule, value, callback) => {
      if(this.orderForm.is_safe == 1){
        if (value) {
          var re = /^[0-9]+.?[0-9]*$/; //判断字符串是否为数字及浮点型
          if(re.test(value)){
            callback();
          }else{
            callback(new Error('请输入正确的值'));
          }
        } else {
          callback(new Error('请输入货值数量'));
        }
      }else{
        callback();
      }

    };
    return {
      geetestData:{},
      userinfo:{},
      loading:false,
      startPort: "",
      startsPort: "",
      startCode: "",
      startType: "",
      startWeight: "",
      startVolume: "",
      startCabinet: "",
      startCabinets: "",
      startCabinetNum: "",
      isportabled: false,
      iscodeabled: false,
      istypeabled: false,
      isCabinetabled: false,
      PortVisible: false,
      CodeVisible: false,
      PortsVisible: false,
      CodesVisible: false,
      WeightVisible: false,
      VolumeVisible: false,
      CabinetVisible: false,
      CabinetNumViseble: false,
      listType: "fba_fcl",
      activeName: "first",
      seqs: "",
      searchtypes: "0",
      hotPortList: [],
      BaseCodeList: [],
      booking_type:'',
      booking_list:{
        1:'整箱',
        2:'拼箱',
      },
      typeList: [
        {
          label: "4",
          value: "美森海派",
        },
        {
          label: "5",
          value: "以星海派",
        },
        {
          label: "6",
          value: "盐田海派",
        },
        {
          label: "7",
          value: "海卡",
        },
        {
          label: "8",
          value: "空派",
        },
      ],
      CabinetType: [
        {
          label: "20GP",
          value: "20gp",
        },
        {
          label: "40GP",
          value: "40gp",
        },
        {
          label: "40HC",
          value: "40hc",
        },
        {
          label: "45HC",
          value: "45hc",
        },
      ],
      isChoose: 1,
      channelList: [
        {
          img: require("../../assets/img/mindex/icon_fcl.png"),
          label: "美国整柜",
        },
        {
          img: require("../../assets/img/mindex/icon_bulk_cargo.png"),
          label: "散货海运卡派",
        },
        {
          img: require("../../assets/img/mindex/icon_zim.png"),
          label: "以星快船",
        },
        {
          img: require("../../assets/img/mindex/icon_speedster.png"),
          label: "美森快船",
        },
        {
          img: require("../../assets/img/mindex/icon_yantian.png"),
          label: "盐田海派",
        },
        {
          img: require("../../assets/img/mindex/icon_air_parcel.png"),
          label: "美国空派",
        },
      ],
      total: 200,
      distance: 0,
      dialogVisible:false,
      dialogVisibleOrder:false,
      dialogVisibleOrderSuccess:false,
      uploadData: {
        line:'美线',
        freight_type:'',
      },
      handleUploadsApi: `${axios.http() + baseApi.sofreight_api}/FbaBooking/uploadOneKeyBooking`,
      handleDownApi: `${axios.http() +baseApi.sofreight_api}/FbaBooking/downOneKeyTpl`,
      authenticationUrl:baseApi.sofreight_api + '/Identification.html?is_fba=1',
      fileList:[],
      login_type:1,
      rules:{
        goods_price: [
          { validator: validateGood, trigger: 'blur' }
        ],
        booking_type: [
          { required: true, message: '请选订单类型', trigger: 'change' }
        ],
      },
      rulesUser:{
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'change' }
        ],
        veriycode: [
          { required: true, message: '请填写验证码', trigger: 'change' }
        ],
        username: [
          { required: true, message: '请填写账号', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'change' }
        ],
      },
      safe_good:{
        safe_price:'',
        goods_price:'',
      },
      orderForm:{
        line:'美线',
        freight_type:'',
        pas:1,
        booking_type: '',
        mobile:'',
        username:'',
        veriycode:'',
        password:'',
        is_safe:0,
        safe_price:'',
        goods_price:'',
        source_po:'7627d4e1c9e1e7ca4fcbb4055b7e784f'
      },
      sf_data:{head:{},cargo:{}},
      canGetCode:false,
      sendCodeText:'验证码',
      codeEndTime:false,
    };
  },

  //监听属性
  computed: {
    isAuth() {
      return function(val) {
        if(val ==  null || !val){
          return true
        }else if (parseInt(val) < 1){
          return true
        }
        return false;
      }
    },
  },

  //监控data中的数据变化
  watch: {},

  beforeMount() {
    this.total =
      document.documentElement.clientHeight || document.body.clientHeight;

    // debugger
  },
  //方法集合
  methods: {
    toown(){
      window.open('https://webpage.qidian.qq.com/2/chat/h5/index.html?linkType=1&env=ol&kfuin=2885822797&fid=956&key=96a6cdc3dab9e8c0318256ded88782c2&cate=7&source=0&isLBS=0&isCustomEntry=0&type=10&ftype=1&_type=wpa&qidian=true&translateSwitch=0&roleValue=0&roleData=3007864257')
    },
    //联系我们
    tous(){
      window.open('https://webpage.qidian.qq.com/2/chat/pc/index.html?linkType=1&env=ol&kfuin=2885822797&fid=956&key=96a6cdc3dab9e8c0318256ded88782c2&cate=7&source=0&isLBS=0&isCustomEntry=0&type=10&ftype=1&_type=wpa&qidian=true&translateSwitch=0&roleValue=0&roleData=3007864257')
    },
    closeSearchBox(show) {
      this.isportabled = !show;
      this.iscodeabled = !show;
      this.istypeabled = !show;
      this.isCabinetabled = !show;
    },
    setItem(type, item, name) {
      console.log(item);
      if (type == "port") {
        if (this.startPort !== "" && this.startPort == item.cname) {
          this.startPort = "";
          // this.startPort = item.cname;
        }
        this.startPort = item.cname;
        this.startsPort = item.name;
        this.seqs = item.seq;
        this.isportabled = false;
      } else if (type == "wh") {
        this.startCode = item.value;
        this.iscodeabled = false;
      } else if (type == "type") {
        this.startType = item.value;
        this.searchtypes = item.label;
        this.istypeabled = false;
      } else if (type == "cabinet") {
        this.startCabinet = item.code;
        this.startCabinets = item.label;
        this.isCabinetabled = false;
      }
    },
    //
    goUrl() {
      this.$router.push({ path: "/recruit" });
    },
    smoothDown() {
      if (this.distance < this.total) {
        this.distance += 10;
        document.body.scrollTop = this.distance;
        document.documentElement.scrollTop = this.distance;
        setTimeout(this.smoothDown, 10);
      } else {
        document.body.scrollTop = this.total;
        document.documentElement.scrollTop = this.total;
      }
    },
    //点击下滑
    goNext() {
      this.distance =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.smoothDown();
    },
    //手机端点击tab
    mtoClick(val) {
      this.isChoose = val;
      this.onFocus();
    },
    //类型选择
    typeChange(val) {
      this.searchtypes = val;
    },
    //选择起运港
    selectPort(val) {
      let list = this.hotPortList;
      for (let i = 0; i < list.length; i++) {
        if (val == list[i].name) {
          this.seqs = list[i].seq;
          this;
        }
      }
      // console.log(this.seqs);
      // console.log(this.startPort);
    },

    polFocus() {
      this.isportabled = true;
      this.onFocus();
      this.$refs.searchbox.open("port");
    },
    codeFocus() {
      this.iscodeabled = true;
      this.onFocus();
      this.$refs.searchbox.open("wh");
    },
    typeFocus() {
      this.istypeabled = true;
      this.onFocus();
      this.$refs.searchbox.open("type");
    },
    CabinetFocus() {
      this.isCabinetabled = true;
      this.onFocus();
      this.$refs.searchbox.open("cabinet");
    },
    onFocus() {
      this.PortVisible = false;
      this.CodeVisible = false;
      this.PortsVisible = false;
      this.CodesVisible = false;
      this.WeightVisible = false;
      this.VolumeVisible = false;
      this.CabinetVisible = false;
      this.CabinetNumViseble = false;
    },
    orderFn(val){
      this.dialogVisible = true;
      if(val == 'first'){
        this.orderForm.booking_type = '2';
      }else{
        this.orderForm.booking_type = '1';
      }

      this.uploadData.freight_type = this.orderForm.booking_type;
      console.log(val,'valval')
    },


    deleteFile(){
      this.sf_data = {head:{},cargo:{}};
      this.fileList = []
    },
    phone_fn(val){
      if(!this.canGetCode){
        if(!this.orderForm.mobile){
          this.$message({
            message: '请填写手机号',
            type: 'warning'
          });
          return false;
        }
        if(Object.keys(this.geetestData).length > 0){
          let data = {mobile:this.orderForm.mobile,...this.geetestData}
          console.log(data,'geetestData')
          mobileApi(data).then((res) => {
            console.log(res,'mobileApi')
            this.codeCaptchaTime()
          })
              .catch((err) => {
                console.log(err);
              });
        }else{
          this.$message({
            message: '请滑动验证',
            type: 'warning'
          });
        }
      }

    },
    comfire_order(val){
      this.$refs[val].validate((valid) => {
        if (valid) {
          if(this.fileList.length > 0){
            this.dialogVisibleOrder = true;
          }else{
            this.$message({
              message: '请选择上传的文件',
              type: 'warning'
            });
          }
        }
      });
    },
    //查询
    GoSearchs(search) {
      if (search == 0) {
        if (this.startPort == "") {
          this.$toast("起运港不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startCode == "") {
          this.$toast("仓库代码不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startCabinet == "") {
          this.$toast("柜型不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startCabinetNum == "") {
          this.$toast("柜量不能为空", {
            durtaion: 200,
          });
          return false;
        } else {
          this.GoFbaSearch(search);
        }
      } else {
        if (this.startPort == "") {
          this.$toast("起运港不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startCode == "") {
          this.$toast("仓库代码不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startWeight == "") {
          this.$toast("重量不能为空", {
            durtaion: 200,
          });
          return false;
        } else if (this.startVolume == "") {
          this.$toast("体积不能为空", {
            durtaion: 200,
          });
          return false;
        } else {
          this.GoFbaSearch(search);
        }
      }
    },
    GoSearch(search) {
      if (search == 0) {
        //整柜
        if (
          this.startPort != "" &&
          this.startCode != "" &&
          this.startCabinet != "" &&
          this.startCabinetNum != ""
        ) {
          this.GoFbaSearch(search);
        } else {
          if (this.startPort == "") {
            this.PortVisible = true;
          } else {
            this.PortVisible = false;
          }
          if (this.startCode == "") {
            this.CodeVisible = true;
          } else {
            this.CodeVisible = false;
          }
          if (this.startCabinet == "") {
            this.CabinetVisible = true;
          } else {
            this.CabinetVisible = false;
          }
          if (this.startCabinetNum == "") {
            this.CabinetNumViseble = true;
          } else {
            this.CabinetNumViseble = false;
          }
        }
      } else {
        //拼柜
        if (
          this.startPort != "" &&
          this.startCode != "" &&
          this.startWeight != "" &&
          this.startVolume != ""
        ) {
          this.GoFbaSearch(search);
        } else {
          if (this.startPort == "") {
            this.PortsVisible = true;
          } else {
            this.PortsVisible = false;
          }
          if (this.startCode == "") {
            this.CodesVisible = true;
          } else {
            this.CodesVisible = false;
          }
          if (this.startWeight == "") {
            this.WeightVisible = true;
          } else {
            this.WeightVisible = false;
          }
          if (this.startVolume == "") {
            this.VolumeVisible = true;
          } else {
            this.VolumeVisible = false;
          }
        }
      }
    },
    //跳转
    GoFbaSearch(v) {
      // console.log(v);
      // console.log(this.startPort);
      let baseurl = "http://v4.sofreight.com/fba_freight_from_";
      let appurl = "https://m.sofreight.com/freight/fba/fcl_";
      let url = "";
      if (v == 0) {
        //整柜
        url = !this.$root.isApp
          ? baseurl +
            this.startsPort +
            "_to_" +
            this.startCode +
            "_" +
            this.seqs +
            "_w" +
            this.startCabinet +
            "_v" +
            this.startCabinetNum +
            "_f11_l0.html"
          : appurl +
            this.startsPort +
            "_" +
            this.startCode +
            "_w" +
            this.startCabinet +
            "_v" +
            this.startCabinetNum +
            ".html?pol_seq=" +
            this.seqs +
            "&pol_name=" +
            this.startsPort +
            "&warehouse_code=" +
            this.startCode +
            "&weight=" +
            this.startCabinet +
            "&volume=" +
            this.startCabinetNum +
            "&freight_type=11";
        window.location.href = url;
      } else {
        url = !this.$root.isApp
          ? baseurl +
            this.startsPort +
            "_to_" +
            this.startCode +
            "_" +
            this.seqs +
            "_w" +
            this.startWeight +
            "_v" +
            this.startVolume +
            "_f12_l" +
            this.searchtypes +
            ".html"
          : "https://m.sofreight.com/freight/fba/lcl_" +
            this.startsPort +
            "_" +
            this.startCode +
            "_w" +
            this.startWeight +
            "_v" +
            this.startVolume +
            ".html?pol_seq=" +
            this.seqs +
            "&pol_name=" +
            this.startsPort +
            "&warehouse_code=" +
            this.startCode +
            "&weight=" +
            this.startWeight +
            "&volume=" +
            this.startVolume +
            "&freight_type=12" +
            "&lcl_type=_l0";
        window.location.href = url;
      }
    },
    //初始化仓库代码
    initFbaWh() {
      getFbaWh().then((res) => {
        this.BaseCodeList = res.Message.fbaCountryCodeList["美国"];
      });
    },

    successFile(res){
      let infoFile = res.data || {}
      if(res.status == 1){
          let fileName = infoFile.file;
          let file = {name:fileName.file_info.name,url:fileName.file_path};
          this.fileList.push(file)
        if(infoFile.head && infoFile.cargo){
          this.sf_data.head = infoFile.head;
          this.sf_data.cargo = infoFile.cargo;
          this.orderForm.goods_price = infoFile.goods_price ? infoFile.goods_price : 0;
          this.insureFn(infoFile.goods_price)
          this.safe_good.goods_price = this.orderForm.goods_price
          this.safe_good.safe_price  = this.orderForm.safe_price
        }
      }else{
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    downFile(){
      let url =  `${this.handleDownApi}?line=美线&freight_type=${this.orderForm.booking_type}`
      window.open(url);
    },
    nextApiFn() {
     /*nextApi({request_source:1,api_token:'11fb36870054f651af473047a494388d'}).then((res) => {
        console.log(res)
      });*/
    },
    // 重新拉取港口数据列表
    initPort(search) {
      this.loading = true;
      if (search !== "") {
        let data = {
          keyword: search,
        };
        // 按条件拉取港口列表数据
        getPort(data).then((res) => {
          this.hotPortList = res.result;
        });
      } else {
        // 清空所选港口时重新拉取热门港口
        this.hotPortList = [];
      }
    },
    //选择整柜还是拼柜
    handleClick(tab, event) {
      console.log(this.activeName,'activeName');
      if (tab.label == "整柜") {
        this.listType = "fba_fcl";
      } else if (tab.label == "拼柜") {
        this.listType = "fba_lcl";
      } else {
        this.listType = "fba_fcl";
      }

      if(this.activeName == 'first'){
        this.isChoose = 1;
      }else{
        this.isChoose = 0;
      }

      (this.startPort = ""),
        (this.startCode = ""),
        (this.startType = ""),
        (this.startWeight = ""),
        (this.startVolume = ""),
        (this.startCabinet = ""),
        (this.startCabinetNum = ""),
        this.onFocus();
      this.initHotPort();
    },
    initHotPort() {
      // this.hasMenuList = false;
      let data = {
        type: this.listType,
      };
      getHotPorts(data)
        .then((res) => {
          this.hotPortList = res.result;
          // let row = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChange(res) {
      this.fileList = fileList.slice(-3);
    },
    sumbit_order(ref){
      if(!this.userinfo){
        this.$refs[ref].validate((valid) => {
          if(!valid){
            return false;
          }else{
            if(this.orderForm.pas == 1){
              this.orderForm.username = '';
              this.orderForm.password = '';
            }else{
              this.orderForm.mobile = '';
              this.orderForm.veriycode = '';
            }
          }
        })
      }else{
        this.orderForm.pas = 0;
      }
      if(this.orderForm.is_safe == 0){
        this.orderForm.safe_price = '';
        this.orderForm.goods_price = '';
      }
      this.orderForm.freight_type = this.orderForm.booking_type;

      console.log(this.sf_data,'this.sf_data.headthis.sf_data.head')
      this.loading = true;
      saveOrderApi({data:this.sf_data,... this.orderForm}).then((res) => {
        if(res.status == 1){
          this.initOrder();
          this.dialogVisibleOrderSuccess = true;
        }else{
          let message = res.info;
          let type = '';
          this.loading = false;
          this.$message({
            message: message,
            type: type
          });
        }
      }).catch((err) => {
            this.loading = false;
        console.log(err);
      });
    },
    safeFn(){
      if(this.orderForm.is_safe == 0){
        this.orderForm.safe_price = '';
        this.orderForm.goods_price = '';
      }else{
        this.orderForm.safe_price = this.safe_good.safe_price;
        this.orderForm.goods_price = this.safe_good.goods_price;
      }
    },
    initOrder(){
      this.dialogVisible = false;
      this.dialogVisibleOrder = false
     this.orderForm = {
            line:'美线',
            freight_type:'',
            pas:1,
            booking_type: '',
            mobile:'',
            username:'',
            veriycode:'',
            password:'',
            is_safe:0,
            safe_price:'',
            goods_price:'',
            source_po:'7627d4e1c9e1e7ca4fcbb4055b7e784f'
      };
      this.sf_data = {head:{},cargo:{}};
      this.fileList = []
    },
    backFn(){
      this.dialogVisibleOrder = false;
    },
    backIndex(){
      this.dialogVisibleOrderSuccess = false;
      this.$router.go(0)
    },
    userOpen(){
      let url =  `${this.authenticationUrl}`
      window.open(url);
    },
    validCode(val){
      console.log(val,'validCode')
      this.geetestData = val;
    },
    codeCaptchaTime() {
      let totalTime = 60;
      this.canGetCode = true;
      this.sendCodeText = totalTime + "s";
      this.codeEndTime = true;
      let clock = setInterval(() => {
        totalTime--;
        this.sendCodeText = totalTime + "s ";
        if (totalTime < 0) {
          clearInterval(clock);
          this.sendCodeText = "验证码";
          totalTime = 60;
          this.canGetCode = false; //这里重新开启
          this.codeEndTime = false;
        }
      }, 1000);
    },

    insureFn(val){
      let _val = parseFloat(val);
      if(val && _val > 0){
        if(parseFloat(_val*2/1000) > 60){
          this.orderForm.safe_price = parseFloat(_val*2/1000);
        }else{
          this.orderForm.safe_price = 60;
        }
      }else{
        this.orderForm.safe_price = '';
      }
    }

  },

  //创建完成 访问当前this实例
  created() {
    this.userinfo = Object.keys(this.$store.state.user.info).length > 0 ? this.$store.state.user.info : '';
    this.initHotPort();
    this.initFbaWh();
    // this.handleClick()
    // this.startPort = this.startPort

    // if (localStorage.getItem('values')) {
    //   let val = JSON.parse(localStorage.getItem('values'))
    //   console.log(val);
    //   this.startPort = val.port
    // }

    this.nextApiFn()
  },
  //挂载完成 访问DOM元素
  mounted() {},
};
</script>
<style scoped lang="scss">
.index {
  width: 100%;
  min-width: 1194px;
  height: 680px;
  position: relative;
  // background: hsla(210, 78%, 33%, 1);
  .main {
    position: absolute;
    width: 30%;
    min-width: 200px;
    top: 93px;
    left: 20%;
    color: #fff;
    padding: 70px 0 0 0;
    h3 {
      width: 100%;
      font-size: 72px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: normal;
    }
    h4 {
      width: 100%;
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      margin-bottom: 34px;
      line-height: normal;
    }
    p {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.6;
    }
  }
  .search-class {
    position: absolute;
    width: 20%;
    // height: 461px;
    background: #ffffff;
    border-radius: 4px;
    top: 93px;
    right: 20%;
    padding: 34px 40px;
    .search-ul {
      width: 100%;
      margin-top: 10px;
      .search-li {
        width: 100%;
        margin-bottom: 18px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: space-between;
        .search-titles {
          display: block;
          padding-left: 10px;
          width: 60px;
          font-size: 14px;
          font-weight: 400;
          color: #696d71;
        }
        .search-inp {
          width: 80%;
        }
        .search-unit {
          font-size: 16px;
          font-weight: 400;
          color: #1e2021;
        }
      }
    }
  }

}
.search_main {
  display: flex;
  width: 100%;
  align-items: center;
}

.comfire-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .search-btn {
    width: 47%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    margin-top: 30px;
    cursor: pointer;
    &:hover{
      opacity: 0.6;
    }
  }

  div:nth-child(odd){
    background: #1D78D2;
  }
  div:nth-child(even){
    background: #d51e24;
  }
  .search-btn:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}



.file-dialog{
  ::v-deep .el-dialog{
    background: #FFFFFF;
    border-radius: 8px;
    width: 400px;
  }
}

.comfire-dialog{
  &>::v-deep.el-dialog{
    width: 800px;
    background: #FFFFFF;
    border-radius: 8px;
  }
}

.order-success{
  font-family: Microsoft YaHei;
  &>::v-deep.el-dialog{
    width: 400px;
    background: #FFFFFF;
    border-radius: 8px;
  }
  &-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    div{
      display: flex;
      justify-content: center;
      margin: 0 20px;
      margin-bottom: 20px;
    }
    &-title{
      font-size: 24px;
      font-weight: 400;
      color: #343537;
      line-height: 42px;
    }
    &-desc{
      font-size: 14px;
      font-weight: 400;
      color: #919499;
      line-height: 22px;
    }
    &-buttom{
      width: 340px;
      height: 40px;
      background: #1D78D2;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 42px;
      cursor: pointer;
      &:hover{
        opacity: 0.6;
      }
    }
    &-back{
      font-size: 16px;
      font-weight: 400;
      color: #6B6E72;
      line-height: 42px;
      width: 340px;
      height: 40px;
      background: #EBEFF4;
      border-radius: 2px;
      &:hover{
        opacity: 0.6;
      }
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__body{
  padding: 0;
}

.order-file{
  width: 400px;
  padding: 20px 20px;
  ::v-deep .el-input__inner{
    border: 1px solid #DCDFE6 !important;
    height: 30px;
    width: 273px;
  }

  ::v-deep .el-input{
    width: 273px;
  }
  ::v-deep .el-form-item{
    margin-bottom: 0;
  }
  &-main{
    ::v-deep .el-form-item .el-form-item__error{
      top: 86%;
      left: 70px;
    }
    width: 100%;
    height: 100%;
    &-top{
      .insure-input{
        margin-left: 68px;
      }
      .form-insure{
        margin-bottom: 10px;
      }
    }
    &-center{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      div{
        margin-right: 10px;
        a{
          color: #1D78D2;
          cursor: pointer;
          &:hover{
            color: #409EFF;
          }
        }
      }
    }
    .file-list{
      margin-left: 66px;
      margin-top: 20px;
      div{
        display: flex;
        align-items: center;
        margin-top: 5px;
        span{
          line-height: 20px;
        }
        img{
          margin-right: 10px;
        }
        .el-icon-delete{
          cursor: pointer;
          &:hover{
            color: #8cc5ff;
          }
        }
      }
    }
    &-bottom{
      display: flex;
      width: 90%;
      height: 40px;
      background: #D51E24;
      border-radius: 2px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 42px;
      margin-top: 35px;
      &:hover{
        opacity: 0.6;
      }
      cursor: pointer;
    }
  }
}

.comfire-order{
  ::v-deep .el-input__inner{
    border: 1px solid #DCDFE6 !important;
    height: 30px;
  }
  &-detail{
    padding:20px 30px;
    font-size: 14px;
    color: #343537;
    line-height: 42px;
    span{
      font-weight: bold;
    }
  }
  .user-title{
    width: 100%;
    background-color: #e6f5ff;
    div{
      padding: 10px 30px;
    }
  }
  &-login{
    padding: 8px 30px;
    &-phone{
      ::v-deep .el-form-item{
        margin-bottom: 0;
      }
      margin-bottom: 20px;
      //padding: 20px 0;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      //align-items: center;
      &>div:nth-child(odd){
        width: 11%;
        margin-top: 10px;
      }
      &>div:nth-child(even){
        width: 42%;
      }
    }
    &-message{
      ::v-deep .el-form-item__content{
       width: 100%;
      }
      padding: 20px 0;
      display: flex;
      flex-direction: row;
      //align-items: center;
      &>div:nth-child(odd){
        width: 11%;
        margin-top: 10px;
      }
      &>div:nth-child(even){
        width: 42%;
        display: flex;
        flex-direction: row;
        //align-items: center;
        .phone_code{
          margin-top: 5px;
          color: #ffffff;
          margin-left: 10px;
          width: 100px;
          height: 32px;
          line-height: 32px;
          background: #409EFF;
          border-radius: 2px;
          cursor: pointer;
          text-align: center;
          &:hover{
            opacity:0.6;
          }
        }
      }
    }

    .geetest{
      width: 42%;
      padding-left: 81px;
    }
  }

  &-price{
    margin: 0px 20px;
    padding: 20px 30px;
    background-color: #fef4db;
    div{
      float: right;
      line-height: 5px;
      span{
        color: #ff3333;
        font-weight: bold;
      }
    }
  }
  &-button{
    padding: 35px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
      width: 58%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      div{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        cursor: pointer;
        border-radius: 5px;
        &:hover{opacity: 0.6;}
      }
      div:nth-child(odd){
        background: #EBEFF4;
        color: #343537;
        padding: 10px 75px;
      }
      div:nth-child(even){
        background: #D51E24;
        color: #ffffff;
        padding: 10px 60px;
      }

    }
  }
}

.search-tip-icon {
  color: #ff8201;
}
.footer {
  box-sizing: border-box;
  width: 100%;
  padding: 40px 24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 大屏幕（大桌面显示器，大于等于 1200px） */
$screen-lg-min: 1200px;
/* 中等屏幕（桌面显示器，大于等于 992px） */
$screen-md-min: 992px;
/* 小屏幕（平板，大于等于 768px） */
$screen-sm-min: 769px;

$screen-lg: 1366px;
@media (max-width: $screen-lg) {
  .index {
    width: 100%;
    min-width: 1194px;
    height: 680px;
    position: relative;
    // background: #135697;
    .main {
      position: absolute;
      width: 30%;
      min-width: 512px;
      top: 93px;
      left: 10%;
      color: #fff;
      padding: 70px 0 0 0;
    }
    .search-class {
      position: absolute;
      // width: 20%;
      min-width: 350px;
      // height: 461px;
      background: #ffffff;
      border-radius: 4px;
      top: 93px;
      right: 10%;
      padding: 34px 40px;
    }
  }
  .footer {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 24%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.videocontainer {
  width: 100%;
  height: 680px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
}
.ceshi {
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  z-index: -99 !important;
  overflow: hidden !important;
}
._1k6dqj1 {
  width: 100% !important;
  height: auto !important;
}
.mengceng {
  width: 100%;
  height: 680px;
  position: absolute;
  top: 0;
  left: 0;
  background: #0b1218;
  opacity: 0.6;
  z-index: -9;
}
.beijing {
  width: 100%;
  height: 680px;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  // opacity: 0.6;
  z-index: -109;
}
.fullscreenvideo {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  // z-index: -100;
  // -webkit-transform: translateX(-50%) translateY(-50%);
  // transform: translateX(-50%) translateY(-50%);
  // -webkit-transition: 1s opacity;
  // transition: 1s opacity;
}
.indexs {
  width: 100%;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
}
.indexs_img {
  width: 100%;
}
.m_index {
  position: relative;
  width: 100%;
  height: 602.5px;
  flex: 1;
  background-image: url("../../assets/img/mindex/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 16px 18px 0 18px;
  box-sizing: border-box;
  .index_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .m_logo {
      width: 123px;
      height: 23px;
      background-image: url("../../assets/img/mindex/logo_fbahope.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .m_wechats {
      height: 23px;
      width: 90px;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.5);
      // opacity: 0.5;
      border-radius: 28px;
      padding: 0 7px;
      color: #fff;
      font-size: 13px;
      line-height: 23px;
      display: flex;
      justify-items: center;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
      span {
        display: inline-block;
      }
    }
  }

  .m_theme {
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-top: 40px;
  }
  .m_search {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    .search_theme {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      background: #FFFFFF;
      .search_tab {
        display: block;
        width: 100%;
        text-align: center;
        color: #666;
        font-size: 16px;
        font-weight: 500;
        height: 44px;
        line-height: 44px;
        display: flex;
        margin-bottom: 20px;
        .choose_tab {
          display: block;
          font-weight: bold;
          width: 50%;
          background: #fff;
          color: #135697;
        }
        .unchoose_tab {
          display: block;
          width: 50%;
          background: #EEF2F6;
          border-bottom-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        .unchoose_tabs {
          display: block;
          width: 50%;
          background: #EEF2F6;
          border-bottom-right-radius: 6px;
        }
      }
      .search_item {
        padding: 0 20px 20px 20px;
        .item_li {
          position: relative;
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid #E5E5E5;
          padding: 12px 5px 5px 10px;
          flex-flow: row nowrap;
          // justify-content: space-between;
          .item_div {
            width: 100%;
            display: flex;
            justify-items: center;
            align-items: center;
            span {
              display: inline-block;
              min-width: 66px;
              color: #343537;
              font-weight: 500;
              font-size: 14px;
              text-align: justify;
              text-align-last: justify;
              justify-items: center;
              align-items: center;
            }
          }
          .el_arrow {
            color: #CCCCCC;
            position: absolute;
            right: 0;
          }
        }
      }
      .m_searchs_btn {
        padding: 0 20px;
        margin-bottom: 20px;
        .m_search_btn {
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          border-radius: 4px;
          background: #D51E24;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.tonext {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #CCCCCC;
  font-size: 44px;
  .el_down {
    opacity: 0.5;
  }
}
.m_introduce {
  margin-top: 20px;
  width: 100%;
  padding: 18px;
  box-sizing: border-box;
  // height: 100vh;
  h2 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #343537;
  }
  p {
    color: #696D71;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    word-wrap: break-word;
    word-break: normal;
  }
  .m_hope_img {
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
    img {
      width: 100%;
    }
  }
}
.m_channel {
  width: 100%;
  box-sizing: border-box;
  background: #F1F5F8;
  padding: 40px 18px 20px 18px;
  h2 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #343537;
  }
  .channel_ul {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    li {
      background: #fff;
      width: 30%;
      height: 110px;
      padding: 2px 0 15px 0px;
      border-radius: 6px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 70px;
        height: 70px;
      }
      p {
        font-size: 13px;
        font-weight: 500;
        color: #343537;
        text-align: center;
      }
    }
  }
}
.m_recruit {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 18px 40px 18px;
  h2 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #343537;
  }
  .recruit_r {
    margin-bottom: 16px;
    margin-top: 20px;
    span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: #696D71;
      padding: 5px 0;
    }
  }
  .recruit_img {
    width: 100%;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}
.m_about {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0 0 0;
  background: #F1F5F8;
  h2 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343537;
  }
  .to_btn {
    width: 100px;
    height: 40px;
    color: #fff;
    background: #D51E24;
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
  }
  .m_title_m {
    height: 74px;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #222425;
    text-align: center;
    line-height: 74px;
    margin-top: 50px;
  }
}
.inptwidth {
  // width: cacl( 100% - 102'px');
  // flex: 1;
  flex-grow: 1;
  flex-direction: row;
}
.contactUs {
  position: fixed;
  right: 0;
  bottom: 220px;
  width: 40px;
  height: 132px;
  background: #135697;
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  img {
    display: block;
    margin: 0 auto;
    padding: 0 0 5px 0;
    box-sizing: border-box;
  }
  span {
    display: block;
    width: 16px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
.el-input__inner {
  border: none !important;
}
.el-icon-arrow-up:before {
  content: none;
}
.el-tabs__item.is-active {
  color: #135697 !important;
  font-weight: bold !important;
}
.el-tabs__item:hover {
  color: #135697 !important;
}
</style>