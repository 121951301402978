<template>
  <div id="geetest"></div>
</template>
<script>
import { getGeetest } from "@/service/index";
export default {
  data(){
    return {
      list:[],
      validResult:{}
    }
  },
  created(){
    this.getGeetestFn()
  },
  methods: {
    getGeetestFn() {
      // 拉取仓库数据
      getGeetest().then((res) => {
        let data = res;
        console.log(data,'datadatadata')
        //请检测data的数据结构， 保证data.gt, data.challenge, data.success有值
        initGeetest({
              // 以下配置参数来自服务端 SDK
              gt: data.gt,
              challenge: data.challenge,
              offline: !data.success,
              new_captcha: true,
              product: 'popup',
              width: "100%"
            },
            (captchaObj) => {
              // 这里可以调用验证实例 captchaObj 的实例方法
              captchaObj.appendTo("#geetest"); //将验证按钮插入到宿主页面中geetest元素内
              captchaObj.onReady(() => {
                //your code
              }).onSuccess(() => {
                //your code
                this.validResult = captchaObj.getValidate();
                this.$emit('validResultFn', this.validResult)
                // console.log(this)
              }).onError(() => {
                //your code
              })
            })
      }).catch(error => {
        console.log(
            error
        )
      });
    },

  }
}
</script>