<template>
  <div class="loginload">
    <div class="loginload-wait">
      <div class="el-icon-loading"></div>
      <div>正在跳转。。。。。。</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      token:''
    }
  },
  created(){
    let param = this.$route.query;
    this.token = param.token || '';
    this.login();
  },
  methods:{
    login(){
      console.log(this.token,'this.token')
      this.$store.dispatch("getUserInfo",{token:this.token}).then(() => {
        let info = this.$store.state.user.info;
        if(Object.keys(info).length > 1){
          this.$router.push({
            path: '/',
          })
        }
      });
    }
  }

}
</script>

<style scoped lang="scss">
    .loginload{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      &-wait{
        position: absolute;
        top: 380px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        ::v-deep .el-icon-loading:before{
          font-size: 25px;
        }
        div{
          margin-left: 10px;
        }
      }
    }
</style>