<template>
  <div
    :name="showBox ? 'el-fade-in-linear' : 'el-fade-in'"
    style="z-index: 999999"
  >
    <div v-show="!showBox" class="transition-box">
      <div class="searchBox">
        <div class="searchBox-tip searchBox-list__item">
          <i class="el-icon-arrow-left" @click="hideSearchBox"></i>
          <span>{{ title }}</span>
        </div>
        <div
          class="searchBox-header searchBox-list__item"
          v-if="type == 'wh' || type == 'port'"
        >
          <el-input
            class="searchBox-header__input"
            size="mini"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="searchKey"
          ></el-input>
        </div>
        <div class="searchBox-list">
          <ul v-if="type == 'wh'">
            <li
              class="searchBox-list__item"
              v-for="(wh, whIndex) in (searchKey ? searchWhList : list['美国'])"
              :key="whIndex"
              @click="setItem( searchKey ? wh : { 'value': wh }, 'wh')"
            >
              <span>{{searchKey ? wh.value : wh}}</span>
            </li>
          </ul>
          <ul v-if="type == 'port'">
            <li
              class="searchBox-list__item"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
              @click="setItem(item, 'port')"
            >
              <span>{{ item.cname }}</span>
            </li>
          </ul>
          <ul v-if="type == 'type'">
            <li
              class="searchBox-list__item"
              v-for="(type, typeIndex) in typetList"
              :key="typeIndex"
              @click="setItem(type, 'type')"
            >
              <span>{{ type.value }}</span>
            </li>
          </ul>
          <ul v-if="type == 'cabinet'">
            <li
              class="searchBox-list__item"
              v-for="(box, boxIndex) in boxList"
              :key="boxIndex"
              @click="setItem(box, 'cabinet')"
            >
              <span>{{ box.label }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotPorts, getPort, getFbaWh } from "@/service/index";
export default {
  data() {
    return {
      list: [],
      typetList:[
        {
          label: "4",
          value: "美森海派",
        },
        {
          label: "5",
          value: "以星海派",
        },
        {
          label: "6",
          value: "盐田海派",
        },
        {
          label: "7",
          value: "海卡",
        },
        {
          label: "8",
          value: "空派",
        },
      ],
      boxList: [
        // 箱型列表
        {
          label: "20GP",
          code: "20gp",
        },
        {
          label: "40GP",
          code: "40gp",
        },
        {
          label: "40HC",
          code: "40hc",
        },
        {
          label: "45HC",
          code: "45hc",
        },
      ],
      filterList: [],
      searchWhList: [],
      showBox: true,
      searchKey: "",
      active: "日本",
      type: "",
      typeItem: "",
    };
  },
  watch: {
    searchKey(newVal, oldVal) {
      if (this.type == "wh" || this.type == "weight") {
        if (newVal == "") {
          this.searchWhList = [];
        } else {
          this.searchList(newVal);
        }
      } else if (this.type == "port") {
        this.initPort(newVal);
      } else if (this.type == "weight") {
        this.initBoxType(newVal);
      }
    },
  },
  computed: {
    title() {
      if (this.type == "port") {
        return "起运港";
      } else if (this.type == "wh") {
        return "仓库代码";
      } else if (this.type == "type") {
        return "类型";
      } else if (this.type == 'cabinet') {
        return "柜型";
      }else {
        return ''
      }
    },
  },
  created() {
    this.searchKey = "";
  },
  methods: {
    searchList(key) {
      this.searchWhList = [];
      for (let index in this.filterList) {
        if (this.filterList[index].value.toLowerCase().indexOf(key) >= 0) {
          this.searchWhList = Object.assign(
            [],
            this.searchWhList.concat(this.filterList[index])
          );
        }
      }
    },
    hideSearchBox() {
      this.showBox = true;
      let show = true
      this.$emit('closeSearchBox', show)
    },
    open(type, typeItem) {
      this.type = type;
      this.typeItem = typeItem;
      this.searchKey = "";
      if (this.type == "port") {
        this.initHotPort();
      } else if (this.type == "wh") {
        this.active = "日本";
        this.initFbaWh();
      }
      this.showBox = false;
    },
    setItem(item, type) {
      this.hideSearchBox();
      this.$emit("set", type, item);
    },
    setActive(name) {
      this.active = name;
    },
    // 初始化热门港口列表
    initHotPort() {
      let data = {
        type: this.typeItem,
      };
      // 拉取热门港口列表数据
      getHotPorts(data).then((res) => {
        this.list = res.result;
      });
    },
    // 重新拉取港口数据列表
    initPort(search) {
      if (search !== "") {
        let data = {
          keyword: search,
        };
        // 按条件拉取港口列表数据
        getPort(data).then((res) => {
          this.list = res.result;
        });
      } else {
        // 清空所选港口时重新拉取热门港口
        this.initHotPort();
      }
    },
    initFbaWh() {
      // 拉取仓库数据
      getFbaWh().then((res) => {
        this.list = res.Message.fbaCountryCodeList;
        this.filterList = res.Message.fbaCodeList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>